import { useEffect } from 'react';

import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import Typography from '@mui/material/Typography';
import dayjs from 'dayjs';
import { useTranslation } from 'next-i18next';
import enrollmentAPI, { ENROLLMENT_END_POINT } from 'src/api/enrollment';
import { useRequest, useProfile } from 'src/helpers/use';
import { getDaysLeft, numberWithCommas } from 'src/helpers/utils';
import { IEnrollment } from 'src/interfaces';

import { Props } from './card';

type CardFooterProps = {
  matches: boolean;
  setCanLearn: (canLearn: boolean) => void;
  isVertical?: boolean;
} & Props;

const CardFooter = ({ course, matches, setCanLearn, isVertical }: CardFooterProps) => {
  const { t } = useTranslation('common');
  const { profile, isLoading } = useProfile();
  const { isLoading: enrollmentsLoading, isValidating } = useRequest<IEnrollment[]>(
    profile ? ENROLLMENT_END_POINT.ENROLLMENTS_ME : null,
    enrollmentAPI.fetchCoursesEnrollment,
    { revalidateIfStale: false },
  );
  const expiry_time = course.enrollment?.expiry_time;
  const isExpired = course.enrollment?.is_expired;
  useEffect(() => {
    if (course.enrollment) {
      if (isExpired && course.product.redirect_url) {
        setCanLearn(false);
      } else if (!course.preorder || isExpired) {
        setCanLearn(true);
      }
    }
  }, [course.enrollment]);

  const renderFooter = () => {
    let courseText = null;
    let title = '';
    const textBaht = t('home.course_card_baht');

    if (course.bundle_items) {
      const enrolled = course.bundle_items.every((item) => item.enrollment);
      if (!enrolled) {
        courseText =
          matches || isVertical ? (
            <>
              {course.price === course.full_price || (course.full_price === 0 && course.price > 0) ? (
                <Typography color="primary.main" variant="h5_deprecated" component="span">
                  {numberWithCommas(course.price)} {textBaht}
                </Typography>
              ) : (
                <>
                  <Typography color="error.dark" variant="h5_deprecated" component="span">
                    {numberWithCommas(course.price)} {textBaht}
                  </Typography>
                  <Typography
                    className="strike-center discount"
                    color="text.disabled"
                    sx={{ ml: 1.5 }}
                    variant="h5_deprecated"
                    component="span"
                  >
                    {numberWithCommas(course.full_price)} {textBaht}
                  </Typography>
                </>
              )}
            </>
          ) : (
            <Grid container direction="row" justifyContent="flex-end" alignItems="center">
              {course.price === course.full_price || (course.full_price === 0 && course.price > 0) ? (
                <>
                  <Grid item xs={4}>
                    <Typography color="primary.main" variant="h5_deprecated" component="span">
                      {numberWithCommas(course.price)} {textBaht}
                    </Typography>
                  </Grid>
                </>
              ) : (
                <>
                  <Grid item xs={4}>
                    <Typography color="error.dark" variant="h5_deprecated" component="span">
                      {numberWithCommas(course.price)} {textBaht}
                    </Typography>
                  </Grid>
                  <Grid item xs={3} md={2}>
                    <Typography
                      className="strike-center discount"
                      color="text.disabled"
                      variant="h5_deprecated"
                      component="span"
                    >
                      {numberWithCommas(course.full_price)} {textBaht}
                    </Typography>
                  </Grid>
                </>
              )}
            </Grid>
          );
      } else {
        courseText = (
          <Typography sx={{ color: 'primary.main' }} variant="h5_deprecated" component="span">
            {t('home.course_card_continue_learning')}
          </Typography>
        );
      }
    } else {
      const { product, enrollment, preorder, preorder_date } = course;
      const originalPrice = product?.originalPrice || 0;
      const salePrice = product?.salePrice || 0;
      const isCpd = product?.isCpd;
      if (!enrollment || isCpd) {
        if (product?.product_skus[0].is_free) {
          courseText = (
            <Typography color="primary.main" variant="h5_deprecated" component="span">
              {t('home.course_card_free')}
            </Typography>
          );
        } else if (originalPrice !== salePrice) {
          if (!originalPrice) {
            courseText = (
              <Typography color="primary.main" variant="h5_deprecated" component="span">
                {numberWithCommas(salePrice)} {textBaht}
              </Typography>
            );
          } else {
            courseText =
              matches || isVertical ? (
                <>
                  <Typography color="error.dark" variant="h5_deprecated" component="span">
                    {numberWithCommas(salePrice)} {textBaht}
                  </Typography>
                  <Typography
                    className="strike-center discount"
                    color="text.disabled"
                    sx={{ ml: 1.5 }}
                    variant="h5_deprecated"
                    component="span"
                  >
                    {numberWithCommas(originalPrice)} {textBaht}
                  </Typography>
                </>
              ) : (
                <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                  <Grid item xs={8}>
                    <Typography color="error.dark" variant="h5_deprecated" component="span">
                      {numberWithCommas(salePrice)} {textBaht}
                    </Typography>
                  </Grid>
                  <Grid item xs={4} md={2.8}>
                    <Typography
                      className="strike-center discount"
                      color="text.disabled"
                      variant="h5_deprecated"
                      component="span"
                    >
                      {numberWithCommas(originalPrice)} {textBaht}
                    </Typography>
                  </Grid>
                </Grid>
              );
          }
        } else {
          courseText = (
            <Typography sx={{ color: 'primary.main' }} variant="h5_deprecated" component="span">
              {numberWithCommas(originalPrice)} {textBaht}
            </Typography>
          );
        }
      } else {
        if (!expiry_time) {
          title = t('home.course_card_no_expiry');
        } else if (isExpired) {
          title = t('home.course_card_expired');
        } else {
          title = getDaysLeft(expiry_time)
            ? t('home.course_card_days_left', {
                days: getDaysLeft(expiry_time) < 0 ? 0 : getDaysLeft(expiry_time),
              })
            : t('home.course_card_expire_today', {
                time: dayjs(expiry_time).format('HH:mm'),
              });
        }
        if (preorder) {
          courseText = (
            <Typography variant="h5_deprecated" component="span">
              ${t('home.course_card_available_after')} {preorder_date}
            </Typography>
          );
        } else if (isExpired) {
          courseText = (
            <Typography sx={{ color: 'primary.main' }} variant="h5_deprecated" component="span">
              {t('home.course_card_click_for_extend')}
            </Typography>
          );
        } else {
          courseText = (
            <Typography sx={{ color: 'primary.main' }} variant="h5_deprecated" component="span">
              {t('home.course_card_continue_learning')}
            </Typography>
          );
        }
      }
      if (preorder && originalPrice) {
        title = t('home.course_card_pre_order');
      }
    }

    if (isLoading || (enrollmentsLoading && isValidating)) {
      return <Skeleton animation="wave" height={40} width="100%" />;
    }
    return (
      <Box className="price">
        <Typography variant="body2_deprecated" component="span">
          {title}
        </Typography>
        <Box className="course-text">{courseText}</Box>
      </Box>
    );
  };

  return renderFooter();
};

export default CardFooter;
