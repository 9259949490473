import { Box, styled } from '@mui/material';

export const UserIconWrapper = styled(Box)`
  background-color: #f5f6f7;
  color: #00532a;

  && svg {
    width: 32px;
    height: 32px;

    ${({ theme }) => theme.breakpoints.up('sm')} {
      width: 50px;
      height: 50px;
    }
  }
`;
