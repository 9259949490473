import { Box } from '@mui/material';
import Card from '@mui/material/Card';
import CardMedia from '@mui/material/CardMedia';
import Grid from '@mui/material/Grid';
import Skeleton from '@mui/material/Skeleton';
import { styled } from '@mui/material/styles';
import Tooltip, { TooltipProps, tooltipClasses } from '@mui/material/Tooltip';

import Button from 'src/components/atoms/button';

export const CardBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-flow: wrap;
  padding-top: 30px;
  align-items: center;
  ${theme.breakpoints.up('xs')} {
    padding: 0 0.25rem;
  }
  ${theme.breakpoints.up('md')} {
    flex-direction: column;
    padding: 0 0.75rem;
  }
  ${theme.breakpoints.up('lg')} {
    flex-direction: row;
    padding: 0;
  }
`,
);

export const CardMediaCourse = styled(CardMedia)(
  ({ theme }) => `
  ${theme.breakpoints.up('xs')} {
    border-radius: 10px !important;
  }
  ${theme.breakpoints.up('md')} {
    border-radius: 0 !important;
    height: auto;
  }
  ${theme.breakpoints.up('lg')} {
    height: 160.72px;
  }
`,
);

export const CardContainerDesktop = styled(Card)`
  border-radius: 10px;
  margin: 0 0.5rem 1rem 0.5rem;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16) !important;
  display: block;
  width: 272px;
  height: 370px;
  position: relative;
  .img {
    border-bottom: 1px solid #eeeeee;
  }
  .price {
    text-align: left;
    .discount {
      font-weight: normal;
      font-size: 24px;
    }
    display: block;
    .course-text {
      height: 25px;
    }
    .box-price-academic {
      display: flex;
      align-items: baseline;
      justify-content: flex-end;
      height: 25px;
      .academic {
        font-size: 18px;
        width: 45%;
        text-align: right;
        font-weight: normal;
      }
      .price-academic {
        width: 34%;
        flex-grow: 1;
      }
    }
    span {
      font-family: 'Lanna';
      font-size: 18px;
      line-height: 1.18;
    }
    div span {
      font-size: 24px;
    }
  }
`;

export const CardContainerMobile = styled(Card)(
  ({ theme }) => `
  ${theme.breakpoints.up('xs')} {
    width: 100%;
    box-shadow: none !important;
    border-radius: 0;
    margin: 0 0.5rem;
    position: relative;
    :not(:last-child) {
      margin-bottom: 1rem;
    }
    .img {
      border: 1px solid #EEEEEE;
      border-radius: 10px;
    }
    .price {
      text-align: right;
      .discount {
        font-weight: normal;
        font-size: 12px;
      }
      color: #676767;
      .box-price-academic {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        .academic {
          font-size: 12px;
          width: 80px;
          text-align: right;
        }
        .price-academic {
          width: 58px;
          text-align: left;
        }
      }

      span {
        font-family: 'Lanna';
        font-size: 12px;
        line-height: 1.18;
      }
      div span {
        font-weight: bold;
        font-size: 14px;
      }
    }
    .divide {
      margin-top: 12px;
    }
  }
  ${theme.breakpoints.up('md')} {
    box-shadow: 0px 3px 6px rgba(0, 0, 0, 0.160784) !important;
    border-radius: 10px !important;
    border: none;
    margin: 0;
    .img {
      border-top: none;
      border-left: none;
      border-bottom: none;
      border-right-color: #EEEEEE;
      border-radius: 0;
    }
    .divide {
      display: none;
    }
    .price {
      .discount {
        font-size: 18px;
      }
      span {
        font-size: 18px;
      }

      .box-price-academic {
        display: flex;
        align-items: baseline;
        justify-content: flex-end;
        .academic {
          font-size: 18px;
          width: 110px;
          text-align: right;
        }
        .price-academic {
          width: 80px;
          text-align: left;
        }
      }
      div span {
        font-size: 18px;
        line-height: 1.18;
      }
    }
  }
`,
);

export const CertBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  justify-content: center;
  align-items: center;
  height: 17.54px;
  padding: 0 10.3px;
  font-size: 12px;
  ${theme.breakpoints.up('md')} {
    height: 30px;
  }
`,
);

export const CertCourseBoxDesktop = styled(CertBox)`
  div {
    font-weight: bold;
  }
  height: 30px;
  background-color: #1e8764;
  color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  right: 0;
  left: auto;
  border-top-left-radius: 15px;
  border-bottom-left-radius: 15px;
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  width: 130px;
  margin-top: 0;
  .text-cert {
    font-size: 1rem;
  }
`;

export const CertCourseBoxMobile = styled(CertBox)(
  ({ theme }) => `
  background-color: #1e8764;
  color: white;
  box-shadow: 0 3px 6px 0 rgba(0, 0, 0, 0.16);
  position: absolute;
  width: 76px;
  border-top-right-radius: 15px;
  border-bottom-right-radius: 15px;
  left: 0;
  margin-top: 8px;
  .text-cert {
    font-size: 9px;
  }
  div {
    font-weight: bold;
  }

  ${theme.breakpoints.up('md')} {
    right: 0;
    left: auto;
    border-top-left-radius: 15px;
    border-bottom-left-radius: 15px;
    border-top-right-radius: 0;
    border-bottom-right-radius: 0;
    width: 130px;
    margin-top: 0;
    .text-cert {
      font-size: 1rem;
    }
  }
`,
);

export const CertDetailBox = styled(CertBox)`
  border-radius: 15px !important;
  width: 140px;
  color: #1e8764;
  border: solid 1px #458566;
`;

export const TitleBoxMobile = styled(Box)(
  ({ theme }) => `
  font-family: 'Lanna';
  display: -webkit-box;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
  div {
    font-weight: bold;
    font-size: 14px;
  }
  .text-card {
    line-height: 1;
    padding-top: 3px;
  }
  -webkit-line-clamp: 2;
  ${theme.breakpoints.up('md')} {
    div {
      font-size: 24px;
    }
    .box-glance div {
      font-size: 18px;
      font-weight: normal;
    }

    .box-glance .icon {
      display: flex;
      justify-content: center;
      padding-top: 2px;
      min-width: 24px;
      max-width: 24px;
      margin-right: 8px;
      color: #00532a;
    }
  }
`,
);

export const Space = styled(Box)`
  margin-bottom: 6px;
`;

export const TitleBoxDesktop = styled(Box)(
  (props: any) => `
    font-family: 'Lanna';
    display: -webkit-box;
    -webkit-box-orient: vertical;
    ${!props.showAll &&
      `
      overflow: hidden;
      text-overflow: ellipsis;
    `}

    .text-card {
      line-height: 1;
      padding-top: 3px;
      max-height: 49px;
    }
    div {
      font-weight: bold;
      font-size: 24px;
    }
    .box-glance {
      min-height: 24px;
      margin-bottom: 5px;
    }
    .box-glance div {
      font-size: 18px;
      font-weight: normal;
      line-height: 1.22;
    }

    .box-glance .icon {
      display: flex;
      justify-content: center;
      padding-top: 2px;
      min-width: 24px;
      max-width: 24px;
      margin-right: 8px;
      color: #00532a;
    }

    ${!props.showAll &&
      `
    -webkit-line-clamp: 2;
    `}
  `,
);

export const DetailBox = styled(TitleBoxDesktop)`
  -webkit-line-clamp: 6 !important;
  overflow: hidden;
  div {
    font-weight: normal;
    font-family: 'Lanna';
    font-size: 18px;
  }
`;

export const DescBoxMobile = styled(TitleBoxMobile)(
  ({ theme }) => `
  -webkit-line-clamp: 1 !important;
  .desc {
    display: none;
  }
  div {
    font-weight: normal;
    font-family: 'Lanna';
    font-size: 14px;
  }
  ${theme.breakpoints.up('md')} {
    .desc {
      display: block;
      line-height: 1.3;
      margin-top: -3px;
      color: #aaaaaa;
    }
    div {
      font-size: 18px;
    }
  }
`,
);

export const DescBoxDesktop = styled(TitleBoxDesktop)`
  -webkit-line-clamp: 1;
  .desc {
    line-height: 1.3;
    margin-top: -3px;
    color: #aaaaaa;
  }
  div {
    font-weight: normal;
    font-family: 'Lanna';
    font-size: 18px;
  }
`;

export const CardMobileBox = styled(Box)(
  ({ theme }) => `
  display: flex;
  flex-direction: column !important;
  ${theme.breakpoints.up('xs')} {
    padding-left: 10px;
    .box {
      width: 100%;
    }
  }
  ${theme.breakpoints.up('xs')} {
    padding-left: 10px;
    .box {
      width: 100%;
    }
  }
  ${theme.breakpoints.up('md')} {
    padding: 18px 16px;
    margin-bottom: 0;
    .box {
      width: 65%;
    }
    .price {
      margin-right: 1rem;
    }
  }
`,
);

export const CardActionContainerMobile = styled(Box)`
  display: grid;
  @media (max-width: 374px) {
    grid-template-columns: 123px 1fr;
  }
  @media (max-width: 767px) and (min-width: 365px) {
    grid-template-columns: 146px 1fr;
  }
  @media (min-width: 768px) {
    grid-template-columns: 310px 1fr;
  }
`;

export const FavoriteBoxMobile = styled(Box)`
  position: absolute;
  z-index: 2;
  .icon-heart {
    stroke: #ebebeb;
    stroke-width: 1px;
  }
  transform: scale(0.7);
  padding-top: 0;
  margin: -6px;

  @media (max-width: 374px) {
    left: 100px;
  }

  @media (max-width: 767px) and (min-width: 365px) {
    left: 110px;
  }
  @media (min-width: 768px) {
    padding-top: 4px;
    left: 260px;
    transform: scale(1);
    margin: 0;
  }
`;

export const FavoriteBoxDesktop = styled(Box)`
  position: absolute;
  z-index: 2;
  .icon-heart {
    width: 30px;
    height: 30px;
    stroke: #ebebeb;
    stroke-width: 1px;
  }
  padding-top: 0;
  top: 2px;
  right: 2px;
`;

export const IconSkeleton = styled(Skeleton)(
  ({ theme }) => `
  margin: 10px;
  width: 25px;
  height: 25px;
  ${theme.breakpoints.up('md')} {
    margin: 6px;
    width: 35px !important;
    height: 35px !important;
  }
`,
);

export const GlanceButton = styled(Button)(
  ({ ...props }: any) => `
  width: ${props.fullWidth ? '100%' : '144px'};
  padding: 6px 12px;
`,
);

export const CustomWidthTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))((props: any) => ({
  [`& .${tooltipClasses.tooltipPlacementRight}`]: {
    margin: '0 16px !important',
  },
  [`& .${tooltipClasses.tooltipPlacementLeft}`]: {
    margin: '0 16px !important',
  },
  [`& .${tooltipClasses.tooltip}`]: {
    minWidth: '350px',
    maxWidth: '350px',
    minHeight: '420px',
    background: 'white',
    boxShadow: '0 3px 10px 0 rgba(0, 0, 0, 0.16)',
    fontSize: '2rem',
    padding: '29.5px 25px !important',
    borderRadius: '10px',
    color: 'black',
    zIndex: 1,
    display: 'flex',
  },
  [`& .${tooltipClasses.arrow}`]: {
    color: 'white',
    width: '20px',
    height: '20px',
    '&:before': {
      boxShadow: `${
        props.placement === 'right' ? '0 1px 2px 1px rgba(0, 0, 0, 0.12)' : '-1px 2px 2px 3px rgba(0, 0, 0, 0.12)'
      }`,
    },
  },
}));

export const AcademicPriceBox = styled(Box)(
  ({ theme }) => `
  ${theme.breakpoints.up('lg')} {
    display: grid;
  }
`,
);

export const BundleImagesBoxMobile = styled(Box)(
  ({ theme }) => `
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-template-rows: 11.82vw 11.82vw;
    gap: 2px;
    border: 1px solid #EEEEEE;
    border-radius: 10px;
    .img-bundle-1 {
      border-top-left-radius: 10px;
      border-bottom: 1px solid #EEEEEE;
      border-right: 1px solid #EEEEEE;
    }
    .img-bundle-2 {
      border-top-right-radius: 10px;
      border-left: 1px solid #EEEEEE;
      border-bottom: 1px solid #EEEEEE;
    }
    .img-bundle-3 {
      border-bottom-left-radius: 10px;
      border-top: 1px solid #EEEEEE;
      border-right: 1px solid #EEEEEE;
    }
    .img-bundle-4 {
      border-bottom-right-radius: 10px;
      border-left: 1px solid #EEEEEE;
      border-top: 1px solid #EEEEEE;
    }
    ${theme.breakpoints.up('md')} {
      gap: 4px;
      border-radius: 0;
      .img-bundle-1 {
        border-radius: 0;
      }
      .img-bundle-2 {
        border-radius: 0;;
      }
      .img-bundle-3 {
        border-radius: 0;;
      }
      .img-bundle-4 {
        border-radius: 0;;
      }
    }
  `,
);

export const BundleImagesBoxDesktop = styled(Box)`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 2px;
  border: 1px solid #eeeeee;
  border-radius: 10px;
  height: 160.72px;
  grid-template-rows: 49% 49%;
  border-bottom: 1px solid #eeeeee;
  border-radius: 0;
  .img-bundle-1 {
    border-radius: 0;
    border-bottom: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
  }
  .img-bundle-2 {
    border-radius: 0;
    border-left: 1px solid #eeeeee;
    border-bottom: 1px solid #eeeeee;
  }
  .img-bundle-3 {
    border-radius: 0;
    border-top: 1px solid #eeeeee;
    border-right: 1px solid #eeeeee;
  }
  .img-bundle-4 {
    border-radius: 0;
    border-left: 1px solid #eeeeee;
    border-top: 1px solid #eeeeee;
  }
`;

export const CertIcon = styled('img')<{ isVertical?: boolean }>(
  ({ theme, isVertical }) => `
  ${theme.breakpoints.down('md')} {
    width: ${isVertical ? '16px' : '10px'};
    height: ${isVertical ? '16px' : '10px'};
    position: relative;
    left: 4px;
  }
  position: relative;
  left: 6px;
  width: 16px;
  height: 16px;
`,
);

export const CollectHoursBox = styled(Box)`
  background: rgba(255, 255, 255, 0.74);
  box-shadow: 0px 3px 6px 1px rgba(0, 0, 0, 0.16);
  border-radius: 13.5px;
  padding: 4px 10px;
  font-family: 'Heavent';
  font-weight: bold;
`;
